<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";
export default {
  name: "NewsDetail",

  metaInfo: { title: "Informasi Selengkapnya" },

  extends: View,

  mixins: [
    LoadSections([
      "news-detail",
      // 'news-latest',
      "info",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "newsdetail",
    },
  },
};
</script>
